import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

document.addEventListener('alpine:init', () => {
    Alpine.data('YR', () => ({
        cityName: '',
        weatherData: [],
        hourlyWeather: [],
        today: null,

        init(predefinedLocation) {
            if (predefinedLocation && predefinedLocation !== 'undefined') {
                switch (predefinedLocation) {
                    case 'faxe':
                        this.fetchWeather('Faxe', 55.25258, 12.12129);
                        break;
                    case 'guldborgsund':
                        this.fetchWeather('Guldborgsund', 54.7648, 11.8715);
                        break;
                    case 'holbaek':
                        this.fetchWeather('Holbæk', 55.71667, 11.71667);
                        break;
                    case 'kalundborg':
                        this.fetchWeather('Kalundborg', 55.68333, 11.08333);
                        break;
                    case 'holbaek':
                        this.fetchWeather('Lolland', 54.77554, 11.4849);
                        break;
                    case 'lolland':
                        this.fetchWeather('Lolland', 54.77554, 11.4849);
                        break;
                    case 'naestved':
                        this.fetchWeather('Næstved', 55.229, 11.7604);
                        break;
                    case 'odsherred':
                        this.fetchWeather('Odsherred', 55.8721, 11.3352);
                        break;
                    case 'ringsted':
                        this.fetchWeather('Ringsted', 55.4428, 11.7901);
                        break;
                    case 'slagelse':
                        this.fetchWeather('Slagelse', 55.4027, 11.3546);
                        break;
                    case 'soro':
                        this.fetchWeather('Sorø', 55.4313, 11.5552);
                        break;
                    case 'stevns':
                        this.fetchWeather('Stevns', 55.3097, 12.3796);
                        break;
                    case 'vordingborg':
                        this.fetchWeather('Vordingborg', 54.89784, 11.88414);
                        break;
                    default:
                        console.log('Ingen prædefineret lokation valgt.');
                }
            } else {
                console.log('Ingen prædefineret lokation. Tjekker localStorage...');
                // Hvis der ikke er en prædefineret lokation, fortsæt med at tjekke lokal lagring
                const now = new Date();
                this.today = this.stripTime(now);

                // Hvis brugeren tidligere har givet tilladelse til at bruge lokation, brug den
                if (localStorage.getItem('useLocation') === 'true') {
                    this.getLocation();
                }
            }
        },

        stripTime(date) {
            return new Date(date.getFullYear(), date.getMonth(), date.getDate());
        },

        isMobileSafari() {
            return navigator.userAgent.includes("Safari") && /iP(ad|hone|od)/.test(navigator.platform);
        },

        getLocation() {
            if (navigator.geolocation) {
                localStorage.setItem('useLocation', 'true');
                navigator.geolocation.getCurrentPosition(this.locationSuccess.bind(this), this.locationError);
            } else {
                alert("Geolokation er ikke understøttet af denne browser.");
            }
        },

        async locationSuccess(position) {
            const lat = position.coords.latitude;
            const lon = position.coords.longitude;
            this.fetchWeatherByLocation(lat, lon);
        },

        locationError() {
            alert("Kunne ikke hente din position. Sørg for, at geolokation er aktiveret.");
        },

        async fetchWeatherByLocation(lat, lon) {
            /*this.cityName = `Lat: ${lat}, Lon: ${lon}`;*/
            this.cityName = `Vejret på din lokation`;
            const url = `https://api.met.no/weatherapi/locationforecast/2.0/compact?lat=${lat}&lon=${lon}`;

            try {
                let options = {};
                if (!this.isMobileSafari()) {
                    options.headers = {
                        'User-Agent': 'MyWeatherApp/1.0'
                    };
                }

                const response = await fetch(url, options);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                this.parseWeatherDataForWeek(data);
                this.parseHourlyWeatherForToday(data);
            } catch (error) {
                console.error('Fejl ved hentning af vejrdata:', error);
                alert("Der opstod en fejl ved hentning af vejret. Prøv igen.");
            }
        },

        async fetchWeather(city, lat, lon) {
            this.cityName = city;
            const url = `https://api.met.no/weatherapi/locationforecast/2.0/compact?lat=${lat}&lon=${lon}`;

            try {
                let options = {};
                if (!this.isMobileSafari()) {
                    options.headers = {
                        'User-Agent': 'MyWeatherApp/1.0'
                    };
                }

                const response = await fetch(url, options);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                this.parseWeatherDataForWeek(data);
                this.parseHourlyWeatherForToday(data);
            } catch (error) {
                console.error('Fejl ved hentning af vejrdata:', error);
                alert("Der opstod en fejl ved hentning af vejret. Prøv igen.");
            }
        },

        parseWeatherDataForWeek(data) {
            this.weatherData = data.properties.timeseries
                .filter((entry, index) => index % 24 === 0)
                .slice(0, 3)
                .map(entry => {
                    const date = new Date(entry.time);
                    const dayString = `${date.getDate()}/${date.getMonth() + 1}`;
                    const dayName = date.toLocaleDateString('da-DK', { weekday: 'long' });
                    let symbol = null;
                    if (entry.data.next_1_hours) {
                        symbol = entry.data.next_1_hours.summary.symbol_code;
                    } else if (entry.data.next_6_hours) {
                        symbol = entry.data.next_6_hours.summary.symbol_code;
                    } else if (entry.data.next_12_hours) {
                        symbol = entry.data.next_12_hours.summary.symbol_code;
                    }

                    const isToday = this.stripTime(date).getTime() === this.today.getTime();

                    return {
                        day: `${dayName} ${dayString}`,
                        isToday: isToday,
                        temperature: entry.data.instant.details.air_temperature,
                        humidity: entry.data.instant.details.relative_humidity,
                        windDirection: entry.data.instant.details.wind_from_direction,
                        windSpeed: entry.data.instant.details.wind_speed,
                        precipitation: entry.data.next_6_hours ? entry.data.next_6_hours.details.precipitation_amount : 0,  // Regn i mm
                        symbol: symbol ? symbol : null,
                    };
                });
        },

        parseHourlyWeatherForToday(data) {
            const now = new Date();
            this.hourlyWeather = data.properties.timeseries
                .filter(entry => {
                    const entryTime = new Date(entry.time);
                    return entryTime > now && entryTime <= new Date(now.getTime() + 12 * 60 * 60 * 1000);  // Næste 12 timer
                })
                .map(entry => {
                    const time = new Date(entry.time);
                    let symbol = null;
                    if (entry.data.next_1_hours) {
                        symbol = entry.data.next_1_hours.summary.symbol_code;
                    } else if (entry.data.next_6_hours) {
                        symbol = entry.data.next_6_hours.summary.symbol_code;
                    }

                    return {
                        time: time.toLocaleTimeString('da-DK', { hour: '2-digit', minute: '2-digit' }),
                        temperature: entry.data.instant.details.air_temperature,
                        symbol: symbol,
                        windSpeed: entry.data.instant.details.wind_speed,
                        windDirection: entry.data.instant.details.wind_from_direction,
                        precipitation: entry.data.next_1_hours ? entry.data.next_1_hours.details.precipitation_amount : 0,  // Regn i mm
                    };
                });
        },

        getWeatherIcon(symbol) {
            if (symbol) {
                return `https://raw.githubusercontent.com/metno/weathericons/main/weather/svg/${symbol}.svg`;
            }
            return '';
        }
    }));
});
